<script lang="ts">
  import Header from "./Header.svelte";
  import Main from "./Main.svelte";
  import Footer from "./Footer.svelte";
</script>

<Header />
<div class="card">
  <Main />
</div>
<Footer />

<style lang="scss">
  .card {
    background: #f4f4f4;
  }
</style>
