<script lang="ts">
  import WifiController16 from "carbon-icons-svelte/lib/WifiController16";
</script>

<header>
  <a href="/"><h1>QR Wi-Fi <WifiController16 /></h1></a>
</header>

<style lang="scss">
  header {
    margin: 1rem 0;

    a {
      text-decoration: none;
    }

    h1 {
      color: #fff;
      font-size: 1.5em;
    }
  }

  @media only screen and (max-width: 768px) {
    header {
      padding: 0 2em;
    }
  }
</style>
