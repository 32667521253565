export var PlausibleEvents;
(function (PlausibleEvents) {
    PlausibleEvents["QrCodeDownloadSuccess"] = "QR Code - Download Success";
    PlausibleEvents["QrCodeDownloadError"] = "QR Code - Download Error";
    PlausibleEvents["QrCodeStartOver"] = "QR Code - Start Over";
    PlausibleEvents["HomeNext"] = "Home - Next";
    PlausibleEvents["SsidNext"] = "SSID - Next";
    PlausibleEvents["PasswordNext"] = "Password - Next";
    PlausibleEvents["EncryptionNext"] = "Encryption - Next";
    PlausibleEvents["HiddenNext"] = "Hidden - Next";
})(PlausibleEvents || (PlausibleEvents = {}));
