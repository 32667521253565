<script lang="ts">
  import { fly } from "svelte/transition";
  import { Button, Dropdown, TextInput } from "carbon-components-svelte";
  import ArrowLeft32 from "carbon-icons-svelte/lib/ArrowLeft32";
  import ArrowRight32 from "carbon-icons-svelte/lib/ArrowRight32";
  import QrCode16 from "carbon-icons-svelte/lib/QrCode16";
  import statefulSwap from "./statefulSwap";
  import Landing from "./Landing.svelte";
  import QrCode from "./QrCode.svelte";
  import { PlausibleEvents } from "./plausibleEvents";

  const { onOutro, transitionTo, state } = statefulSwap(0);

  let ssid = "",
    password = "",
    encryption: "WPA" | "WEP" | "" = "WPA",
    hidden: "Yes" | "No" = "No";

  const encryptionOptions = [
      { id: "0", text: "WPA", value: "WPA" },
      { id: "1", text: "WEP", value: "WEP" },
      { id: "2", text: "None", value: "" },
    ],
    hiddenOptions = [
      { id: "0", text: "Yes", value: "true" },
      { id: "1", text: "No", value: "false" },
    ],
    ssidPlaceholderOptions = [
      "Drop It Like It's Hotspot",
      "FBI Surveillance Van 2",
      "Tell My Wi-Fi Love Her",
      "Every Day I'm Buffering",
      "hide yo kids, hide yo wifi",
      "The LAN before time",
      "silence_of_the_LANs",
      "no more mister wifi",
      "i_believe_wi_can_fi",
      "It Hurts When IP",
    ],
    passwordPlaceholderOptions = [
      "password123",
      "qwerty",
      "cantguessthis",
      "t0psecret",
      "0p3n_sesame",
    ];

  const onEncryptionSelected = async (event) => {
    encryption = event.detail.selectedItem.value;
  };

  const onHiddenSelected = async (event) => {
    hidden = event.detail.selectedItem.value;
  };

  const getRandomPlaceholder = (options: string[]): string => {
    return options[Math.floor(Math.random() * options.length)];
  };

  const reset = () => {
    ssid = "";
    password = "";
    encryption = "WPA";
    hidden = "No";
    transitionTo(1);
  };
</script>

<main>
  {#if $state === 0}
    <div class="step" in:fly={{ y: -20 }} out:fly on:outroend={onOutro}>
      <h2>Fast, Simple Wi-Fi Access 👏</h2>
      <p>
        Connect to your Wi-Fi network in seconds without ever typing a password.
        Let's create a custom code for your home, office or rental property.
      </p>
      <div class="button-container">
        <Button
          icon={ArrowRight32}
          on:click={() => {
            transitionTo(1);
            window["plausible"](PlausibleEvents.HomeNext);
          }}>Get Started</Button
        >
      </div>
      <Landing />
    </div>
  {:else if $state === 1}
    <div class="step" in:fly={{ y: -20 }} out:fly on:outroend={onOutro}>
      <h2>What is your network name?</h2>
      <p>
        This is the name you see when connecting to your Wi-Fi. You may know it
        as your SSID.
      </p>
      <TextInput
        placeholder={getRandomPlaceholder(ssidPlaceholderOptions)}
        bind:value={ssid}
      />
      <div class="button-container">
        <Button
          icon={ArrowRight32}
          on:click={() => {
            transitionTo(2);
            window["plausible"](PlausibleEvents.SsidNext);
          }}
          disabled={ssid === ""}>Next</Button
        >
      </div>
    </div>
  {:else if $state === 2}
    <div class="step" in:fly={{ y: -20 }} out:fly on:outroend={onOutro}>
      <h2>What is your Wi-Fi password?</h2>
      <p>
        This is the password you use to connect to your Wi-Fi. We never store
        your information.
      </p>
      <TextInput
        placeholder={getRandomPlaceholder(passwordPlaceholderOptions)}
        bind:value={password}
      />
      <div class="button-container">
        <Button
          icon={ArrowLeft32}
          iconDescription="Back"
          kind="tertiary"
          on:click={() => transitionTo(1)}
        />
        <Button
          icon={ArrowRight32}
          on:click={() => {
            transitionTo(3);
            window["plausible"](PlausibleEvents.PasswordNext);
          }}>Next</Button
        >
      </div>
    </div>
  {:else if $state === 3}
    <div class="step" in:fly={{ y: -20 }} out:fly on:outroend={onOutro}>
      <h2>How is your network encrypted?</h2>
      <p>
        Most Wi-Fi networks are encrypted. If you're not sure which to option to
        choose, try WPA first. That's the most common.
      </p>
      <Dropdown
        items={encryptionOptions}
        selectedId="0"
        on:select={onEncryptionSelected}
      />
      <div class="button-container">
        <Button
          icon={ArrowLeft32}
          iconDescription="Back"
          kind="tertiary"
          on:click={() => transitionTo(2)}
        />
        <Button
          icon={ArrowRight32}
          on:click={() => {
            transitionTo(4);
            window["plausible"](PlausibleEvents.EncryptionNext);
          }}>Next</Button
        >
      </div>
    </div>
  {:else if $state === 4}
    <div class="step" in:fly={{ y: -20 }} out:fly on:outroend={onOutro}>
      <h2>Is your network hidden?</h2>
      <p>
        Does your network appear when you try to connect? If not, it may be
        hidden.
      </p>
      <Dropdown
        items={hiddenOptions}
        selectedId="1"
        on:select={onHiddenSelected}
      />
      <div class="button-container">
        <Button
          icon={ArrowLeft32}
          iconDescription="Back"
          kind="tertiary"
          on:click={() => transitionTo(1)}
        />
        <Button
          icon={QrCode16}
          on:click={() => {
            transitionTo(5);
            window["plausible"](PlausibleEvents.HiddenNext);
          }}>Create QR Code</Button
        >
      </div>
    </div>
  {:else if $state === 5}
    <div class="step" in:fly={{ y: -20 }} out:fly on:outroend={onOutro}>
      <QrCode
        connectionString={"WIFI:S:" +
          ssid +
          ";T:" +
          encryption +
          ";P:" +
          password +
          ";H:" +
          hidden +
          ";"}
        restart={reset}
      />
    </div>
  {/if}
</main>

<style lang="scss">
  main {
    margin: 1em auto;
  }

  h2 {
    font-size: 1.5rem;
    margin-top: 1.5em;
  }

  p {
    margin: 0.5em 0;
  }

  .button-container {
    font-family: "SharpSansDispNo1-Bold" !important;
    margin: 1em auto;
    text-align: right;
  }

  .step {
    padding: 1em 2em;
  }
</style>
