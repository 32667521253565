<script lang="ts">
  import { Button } from "carbon-components-svelte";
  import Restart32 from "carbon-icons-svelte/lib/Restart32";
  import Download32 from "carbon-icons-svelte/lib/Download32";
  import QrCode from "svelte-qrcode";
  import { PlausibleEvents } from "./plausibleEvents";

  export let connectionString = "",
    restart = () => {};

  const downloadImage = async () => {
    const qrCodeImg = document.getElementsByClassName(
      "qrcode"
    )[0] as HTMLImageElement;
    if (qrCodeImg) {
      const image = await fetch(qrCodeImg.src);
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);

      const link = document.createElement("a");
      link.href = imageURL;
      link.download = "Wi-Fi QR Code";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window["plausible"](PlausibleEvents.QrCodeDownloadSuccess);
    } else {
      console.error("Could not find QR code image");
      window["plausible"](PlausibleEvents.QrCodeDownloadError);
    }
  };
</script>

<div class="container">
  <div class="text-container">
    <h2>Here it is ✨</h2>
    <p>
      Scan this QR code with your device's camera to <em>automagically</em> connect
      to your Wi-Fi network. Just download and print. Happy scanning!
    </p>
  </div>
  <div class="code-container">
    <QrCode value={connectionString} size="280" />
  </div>
  <div class="button-container">
    <Button icon={Restart32} kind="tertiary" on:click={restart}
      >Start Over</Button
    >
    <Button icon={Download32} on:click={downloadImage}>Download</Button>
  </div>
</div>

<style lang="scss">
  .container {
    text-align: center;

    .text-container {
      text-align: left;
      margin-bottom: 1em;

      h2 {
        font-size: 1.5rem;
        margin-top: 1.5em;
      }
    }

    .code-container {
      height: 300px;
      width: 300px;
      background: #fff;
      margin: 0 auto;
      padding: 10px;
    }

    .button-container {
      width: 300px;
      margin: 1em auto 2em;
      display: flex;
      justify-content: space-between;
    }
  }
</style>
