<script lang="ts">
</script>

<footer>
  <span
    >Made by <a href="https://twitter.com/nickflynndev" target="_blank">Nick</a>
    with a computer</span
  >
</footer>

<style lang="scss">
  footer {
    color: rgba(255, 255, 255, 0.5);
    padding-top: 15px;
  }

  footer:hover {
    color: white;
  }

  footer a {
    color: #0f62fe;
    text-decoration: none;
  }

  @media only screen and (max-width: 768px) {
    footer {
      padding: 2em;
    }
  }
</style>
