<script lang="ts">
  import Lightning16 from "carbon-icons-svelte/lib/Lightning16";
  import WifiSecure16 from "carbon-icons-svelte/lib/WifiSecure16";
</script>

<section class="container">
  <div class="image">
    <img src="assets/images/laptop-user-code.jpg" alt="Man using laptop" />
  </div>
  <div class="pros">
    <div>
      <h2>Why Create a Wi-Fi QR Code?</h2>
      <h3>Speed <Lightning16 style="color: #2b69ff" /></h3>
      <p>
        Connecting is point and click. Scan the QR code with your device's
        camera, tap, and you're connected.
      </p>
      <h3>Security <WifiSecure16 style="color: #2b69ff" /></h3>
      <p>
        There is no need to write, or even speak your password out loud. Set a
        strong password and don't worry about how difficult it is to type.
      </p>
    </div>
  </div>
</section>

<style lang="scss">
  .container {
    position: relative;
    height: 650px;

    .image {
      position: absolute;
      left: -120px;
      top: 15px;
      width: 500px;
      height: 335px;

      img {
        width: 500px;
        height: 335px;
      }
    }

    .pros {
      position: absolute;
      top: 300px;
      right: -120px;
      width: 500px;
      background-color: #e3e3e3;
      padding: 1rem 2rem 2rem 2rem;
    }

    .image,
    .pros {
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
    }

    h2 {
      font-size: 1.5rem;
      margin-top: 1em;
    }

    h3 {
      font-size: 1.2rem;
      margin-top: 1em;
    }
  }

  @media only screen and (max-width: 768px) {
    .container {
      margin: 2rem 0;
      height: auto;

      .image {
        img {
          height: auto;
          width: 100%;
        }
      }

      .image,
      .pros {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        height: auto;
        width: 100%;
        padding: 0;
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
</style>
